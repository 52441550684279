import { firstValueFrom } from 'rxjs';
import { UserService } from './../../../service/user.service';
import { TanksService } from './../../../service/tanks.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import { SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';
import { AlertService } from './../../../service/alert.service';
import { DropdownFilterOptions } from 'primeng/dropdown';
import { AssignmentService } from './../../../service/assignment.service';

@Component({
  selector: 'app-assignment',
  templateUrl: './assignment.component.html',
  styleUrls: ['./assignment.component.scss']
})
export class AssignmentComponent implements OnInit {
    assignmentsList: any = [];
    @ViewChild('assignmentTable') assignmentTable: any;

    cols: any = [
        { header: 'Usuario', field: 'userName' },
        { header: 'Correo de usuario', field: 'userEmail' },
        { header: 'Cisterna', field: 'tankName' },
        // { field: 'Code', header: 'C�digo' },
        // { field: 'Code', header: 'C�digo' },
    ];
    textTitle = 'Asignar cisterna a un usuario'
    tanks: any = [];
    users: any = [];
    @ViewChild('createSwal') createSwal: any;
    editData: any = {
        id: null,
        user_id: null,
        tank_id: null,
    };
    editMode = false;
    @ViewChild('deleteSwal') deleteSwal: any;
    idDelete: any;
    filterValue='';

    constructor(
        private tannkService: TanksService,
        private userService: UserService,
        private alertService: AlertService,
        private assignmentService: AssignmentService,
        public readonly swalTargets: SwalPortalTargets) {
            this.saveData = this.saveData.bind(this);
    }

    ngOnInit(): void {
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.
        this.tannkService.getAllActiveTanks().subscribe(response => {
            console.log('response tanks', response)
            if (response.success && response.data && response.data.length > 0) {
                this.tanks = response.data || [];
            }
        });
        this.userService.getAllData().subscribe(response => {
            console.log('response users', response)
            if (response.success && response.data && response.data.length > 0) {
                this.users = response.data || [];
            }
        });
        this.getAssignmentsData();

    }

    getAssignmentsData() {
        this.assignmentService.getAllData().subscribe(resp => {
            console.log('data', resp)
            if (resp.success && resp.data && resp.data.length > 0) {
                this.assignmentsList = resp.data || [];
            } else {
                this.assignmentsList = [];
            }
        }, error => {
            this.assignmentsList = [];
        });
    }

    getFilter(e: any) {
        return this.assignmentTable.filterGlobal(e.target.value, 'contains');
    }

    showModal(data: any = null) {
        this.editData = {
            id: null,
            user_id: null,
            tank_id: null,
        };
        this.editMode = false;

        if (data) {
            this.editData.id = data?.id || null;
            this.editData.user_id = data?.user_id || null;
            this.editData.tank_id = data?.tank_id || null;
            this.editMode = true;
            console.log('data', data);
            console.log('editData', this.editData);
        }
        this.createSwal.title = this.textTitle;
        this.createSwal.fire();
        setTimeout(()=> {
            const dropdowns: any = document.querySelectorAll('div.form-control.vh-6.p-dropdown.p-component');
            console.log('dropdowns', dropdowns)
            dropdowns.forEach((drop: any) => {
                drop.style.height = '6vh';
                drop.style.padding = '0px 0px';
            });

        }, 100)

    }

    async saveData() {
        try {
            let result = null;
            if (this.editMode) {
                result = await firstValueFrom(this.assignmentService.updateData(this.editData, this.editData?.id));
            } else {
                result = await firstValueFrom(this.assignmentService.saveData(this.editData));
            }
            console.log('result', result)
            if (result && result.success) {
                return true;
            }
        } catch (error) {
            console.log('Error al asignar cisterna a usuario', error)
        }
        return Swal.showValidationMessage('Error al asignar cisterna a usuario');
    }

    successFullSave(data: any) {
        this.alertService.fireAlert('Asignaci�n realizada con �xito', 'success');
        this.getAssignmentsData();
    }

    myResetFunction(options: any) {
        options?.reset();
        this.filterValue = '';
    }

    openDeleteModal(id: any) {
        if (id) {
            this.idDelete = id;
            this.deleteSwal.fire();
        }
    }

    deleteData(id: any) {
        if (id) {
            this.assignmentService.deleteData(id).subscribe(data => {
                if (data?.success) {
                    this.getAssignmentsData();
                    this.alertService.fireAlert('Acci�n realizada con �xito', 'success', data?.message);
                } else {
                    this.alertService.fireAlert(data?.message, 'error');
                }
            })
        }
    }
}
