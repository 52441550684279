import { AuthService } from './../demo/service/auth.service';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from "./service/app.layout.service";
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { TokensService } from '../demo/service/tokens.service';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {

    items!: MenuItem[];

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;
    currentUser: any;

    constructor(public layoutService: LayoutService, private router: Router, private authS: AuthService,
        private tokenS: TokensService) {
        this.currentUser = authS.getUserData();
        console.log('currentUser', this.currentUser);
    }

    logout() {
        const swalMix = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success mx-1',
                cancelButton: 'btn btn-danger mx-1'
            },
            buttonsStyling: false
        });
        swalMix.fire({
            title: '�Seguro que desea continuar?',
            text: 'Est� a punto de cerrar sesion',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'S�, cerrar sesi�n',
            cancelButtonText: 'No, Cancelar'
        }).then(resp => {
            if (resp.isConfirmed) {
                const token = localStorage.getItem('currentToken');
                localStorage.clear();
                if (token) {
                    localStorage.setItem('currentToken', token);
                    this.tokenS.deleteToken(token).subscribe(data => {
                        console.log('delete token', data);
                    })
                }
                this.router.navigate(['/login']);
            }
        })
    }
}
