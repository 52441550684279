import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TanksService } from 'src/app/demo/service/tanks.service';
import Swal from 'sweetalert2';
import { SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';
import { AlertService } from 'src/app/demo/service/alert.service';

@Component({
    selector: 'app-tank',
    templateUrl: './tank.component.html',
    styleUrls: ['./tank.component.scss']
})
export class TankComponent implements OnInit, OnDestroy {
    cols: any = [
        { field: 'Name', header: 'Nombre' },
        { field: 'Code', header: 'C�digo' },
        {
            field: 'Active', header: 'Activo', textClass: {
                1: 'text-green-700',
                0: 'text-red-700',
            }
        },
    ];
    tanks: any = [];
    displayModal = false;
    Status: any = [{name: 'Activo', value: 1}, {name: 'Inactivo', value: 0}];
    editMode = false;
    editData: any = {
        Name: '',
        Code: '',
        Active: 1,
    };
    textTitle = '';
    selectID: any;
    @ViewChild('deleteSwal') deleteSwal: any;
    @ViewChild('createSwal') createSwal: any;
    @ViewChild('tanksTable') tanksTable: any;


    constructor(private tanksService: TanksService,
        private alertService: AlertService,
        public readonly swalTargets: SwalPortalTargets) {

    }

    ngOnInit(): void {
        this.loadData();
    }

    loadData() {
        this.tanks = [];
        this.tanksService.getAllData().subscribe(data => {
            if (data?.success) {
                this.tanks = data?.data || [];
                this.tanks.map((x: any) => {

                    x.ActiveString = x.Active === true
                    || x.Active === 1 ? 'Activo' : 'Inactivo';
                    return x;
                })
                console.log('tanks', this.tanks);
            }
        });
    }

    ngOnDestroy(): void {
        this.restorezIndex();
    }

    showModal(datos: any = null) {
        this.textTitle = 'Crear nueva cisterna';
        if (datos) {
            this.editData = {...datos};
            this.editData.Active = this.editData.Active === true
            || this.editData.Active === 1 ? 1 : 0;
            this.editMode = true;
            this.textTitle = 'Editar datos de cisterna';
        }
        this.createSwal.title = this.textTitle;
        this.createSwal.fire();
    }

    restorezIndex() {
        this.editMode = false;
        this.editData = {
            Name: '',
            Code: '',
            Active: 1,
        };
    }

    saveData() {
        const dataSend = {...this.editData}
        dataSend.Active = dataSend.Active === true
        || dataSend.Active === 1 ? true : false;
        console.log('editData', dataSend)
        if (this.editMode) {
            if (dataSend?.id) {
                this.tanksService.updateData(dataSend, dataSend?.id).subscribe(data => {
                    console.log('save ===>', data);
                    if (data?.success) {
                        this.loadData();
                        this.displayModal = false;
                        this.alertService.fireAlert('Acci�n realizada con �xito', 'success', data?.message);
                    } else {
                        this.alertService.fireAlert(data?.message, 'error');
                    }

                });
            }
        } else {
            this.tanksService.saveData(dataSend).subscribe(data => {
                console.log('save ===>', data);
                if (data?.success) {
                    this.loadData();
                    this.displayModal = false;
                    this.alertService.fireAlert('Acci�n realizada con �xito', 'success', data?.message);
                } else {
                    this.alertService.fireAlert(data?.message, 'error');
                }
            });
        }

    }

    openDeleteModal(id: any) {
        if (id) {
            this.selectID = id;
            this.deleteSwal.fire();
        }
    }

    deleteData(id: any) {
        if (id) {
            this.tanksService.deleteData(id).subscribe(data => {
                if (data?.success) {
                    this.loadData();
                    this.alertService.fireAlert('Acci�n realizada con �xito', 'success', data?.message);
                } else {
                    this.alertService.fireAlert(data?.message, 'error');
                }
            })
        }
    }
    getFilter(e: any) {
        return this.tanksTable.filterGlobal(e.target.value, 'contains');
    }
}
